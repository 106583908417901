/* Add this CSS in your stylesheet */
.card {
    width: 350px;
    height: 410px;
    background-color: rgba(236, 230, 230, 0.61);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 6px 20px -7px rgba(0, 0, 0, 0.05);
    border: 1px solid rgb(207, 206, 206);
    margin-top: 15px;
  }
  
  .image-card {
    width: 100%;
    padding-top: 66.66%; 
    position: relative;
  }
  
  .img {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  .buy-container {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
  }


 
  
.user-img{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.user{
    border-radius: 10px;
    width: 310px;
    height: 250px;
    box-shadow: -1px 3px 15px -1px rgba(199, 196, 192, 0.07);
    -webkit-box-shadow: -1px 3px 15px -1px rgba(0,0,0,0.07);
    -moz-box-shadow: -1px 3px 15px -1px rgba(0,0,0,0.07);
    padding: 30px;
}

  
.background-container {
    background: linear-gradient(-45deg, #3A3845, #F7CCAC, #826F66, #C69B7B);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}




@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

/* HOME PAGE STYLING */
.home-bg {
  position: relative;
  width: 100%;
  height: 110vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;

}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 31, 44, 0.895);
}



/* ABOUT US STYLING */

.about-container {
  height: 100vh;
  position: relative;
  width: 100%;
  background-image: url('./images//house5.jpg');
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 31, 44, 0.895);
}

.about-title {
  font-size: 40px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 500;
  color: white;
}


.aboutOurCompany {
  background-color: white;
  width: 100%;
  height: 100%;
}

.about-our-container {
  height: 100vh;
  position: relative;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template: column;
  place-items: center;
  background-color: white;
  height: 100%;
}

.about-our-title{
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 500;
}






/* OUR SERVICE STYLING */
.service-container {
  height: 100vh;
  position: relative;
  width: 100%;
  background-image: url('./images/house1.jpg');
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 31, 44, 0.895);
}
.image-filter {
  filter: grayscale(100%) sepia(10%) hue-rotate(0deg) saturate(1000%) brightness(0.8);
  border-radius: 50px;
  height: 500px;
}



/* PROPERTIES STYLING */

@keyframes roll {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blurImg {
 background-image: url('https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cHJvcGVydHl8ZW58MHx8MHx8fDA%3D&w=1000&q=80');/* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  filter: blur(100px); /* Adjust the blur intensity as needed */
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.animate-roll {
  animation: roll 2s infinite linear; /* Adjust the animation duration as needed */
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Example media query for screens with a max width of 600px */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    /* Stack buttons vertically */
  }

  .button {
    padding: 0.3rem 0.8rem;
    font-size: 12px;
  }
}
.property-container {
    position: relative;
    width: 100%;
    background-image: url('../../images/real-estate-4955093_1280.jpg');
    background-size: cover;
    justify-content: center;
    align-items: center;
    display: flex;
    background-repeat: no-repeat;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  
  .property-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
  }

.property-card {
    width: 300px;
    height: 410px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 6px 20px -7px rgba(0, 0, 0, 0.062);
    margin-top: 15px;
    z-index: 3;
    border: 1px solid rgba(207, 206, 206, 0.669);
}

.property-image-card {
    width: 100%;
    padding-top: 66.66%;
    position: relative;
}

.property-img {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}